<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="currentItem.Name" label="Tên kho" class="input-group--focused"></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="currentItem.Receiver" label="Người nhận" class="input-group--focused"></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="currentItem.Phone" label="Điện thoại" class="input-group--focused"></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="currentItem.Address" label="Địa chỉ" class="input-group--focused"></v-text-field>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
export default {
    computed: {
        ...mapState({
            loading: state => state.chinaStore.loading,
            currentItem: state => state.chinaStore.selected
        }),
        modifyId() {
            return this.$route.params.Pid;
        }
    },
    watch: {
        modifyId() {
            this.refreshData();
        }
    },
    methods: {
        refreshData() {
            if (this.modifyId > 0) {
                this.$store.dispatch("chinaStore/getDetail", this.modifyId);
            }
            else {
                this.$store.commit("chinaStore/setDetail", { data: {
                        ID: 0,
                        Name: '',
                        Receiver: '',
                        Phone: '',
                        Address: ''
                    }
                })
            }
        },
        saveData() {
            this.$store.dispatch("chinaStore/setDetail", {
                data: this.currentItem,
                id: this.currentItem.ID
            });
            window.getApp.$emit("APP_NAVIGATE", "ChinaStoreList");
        },
    },
    mounted() {
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
        if (this.$route.query.uid && this.$route.query.uid !== '') {
            this.currentItem.UID = Number(this.$route.query.uid);
        }
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>